
import Vue from "vue";

export default Vue.extend({
    data() {
        return {
            closed: false,
        };
    },

    methods: {
        close() {
            this.closed = true;
        },
    },
});
