
import de from "./i18n/de";
import en from "./i18n/en";
import VideochatButton from "./videochat/VideochatButton.vue";
import VideochatWidget from "./videochat/VideochatWidget.vue";
import WhatsAppButton from "./whatsapp/WhatsAppButton.vue";
import WhatsAppWidget from "./whatsapp/WhatsAppWidget.vue";
import i18n from "@/app/i18n";
import Vue from "vue";

i18n.mergeLocaleMessage("de", de);
i18n.mergeLocaleMessage("en", en);

export default Vue.extend({
    data() {
        return {
            widgets: [
                [[WhatsAppWidget], WhatsAppButton],
                [[VideochatWidget], VideochatButton],
            ],
            widget: null,
            params: "",
        };
    },

    mounted() {
        addEventListener("message", (event) => {
            if (event.data === "close") {
                this.widget = null;
            }
        });
    },
});
